<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CCol col="6" class="styleHeader">{{$t('message.showRole')}}</CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th>{{$t('message.name')}}</th>
                    <td>{{ role.name }}</td>
                  </tr>
                </table>
               <span> {{$t('message.permission')}}</span>
                                  <table>
                                    <tbody>
                                  <tr>
                                  <th>  {{$t('message.permissionId')}}</th>
                                  <th>  {{$t('message.permissionName')}}</th>
                                  </tr>
                                  <tr v-for=" permission in role.permissions" :key="permission.id">
                                  <td> {{permission.id}} </td>
                                  <td>{{ permission.name.replace(":"," ") }}</td>
                                  <!-- <td> {{payment.customer.name}} </td>-->
                                  </tr>
                                   </tbody>
                                  </table>
            </div>
        </div>
        <CButton color="btn btn-primary" @click="goBack">{{$t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowCities',
  data () {
    return {
      role: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}roles/${this.$route.params.id}`)
      .then((response) => {
        this.role = response.data.data
      })
  },
  methods: {
    goBack () {
      this.citiesOpened ? this.$router.go(-1) : this.$router.push({ path: '/roles' })
    }
  }
}
</script>
<style scoped>
span{
  font-size: 20px;
  margin-top: 10px;
}
table,td ,th{
    border: 1px solid #ddd;
  text-align: right;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 10px;
}

th, td {
  padding: 15px;
}
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
</style>
